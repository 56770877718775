// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!css/vuebar.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/add_new_upsell.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".action-row[data-v-bac73fd6] {\n  padding: 15px;\n  cursor: pointer;\n  margin-top: 0 !important;\n  margin-left: -8px !important;\n  margin-right: -8px !important;\n}\n.action-row__title[data-v-bac73fd6] {\n  margin-left: 10px;\n  color: #93BD20 !important;\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n}\n.upsell-group-edit-card .tile[data-v-bac73fd6] {\n  border-bottom: 1px solid #e4e4e4;\n}\n.upsell-group-edit-card .header .v-list__tile[data-v-bac73fd6] {\n  color: purple;\n}\n.v-card__title[data-v-bac73fd6] {\n  color: #585858 !important;\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n}\n.v-card__text[data-v-bac73fd6] {\n  color: #585858 !important;\n  font-size: 14px;\n  overflow: hidden;\n}\n.v-list[data-v-bac73fd6] {\n  margin-right: 5px;\n}\n.v-list__tile[data-v-bac73fd6] {\n  border-bottom: 1px solid #e4e4e4;\n}\n.v-list__tile__title[data-v-bac73fd6] {\n  color: #585858 !important;\n  font-size: 14px;\n}\n.v-list__tile__action[data-v-bac73fd6] {\n  min-width: 35px;\n  margin-left: 5px;\n}\n.v-list__tile__action .icon[data-v-bac73fd6] {\n  cursor: grab;\n}\n.upsell-logo[data-v-bac73fd6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  margin-left: 5px;\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}\n.delete-logo[data-v-bac73fd6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 25px;\n  cursor: pointer;\n  flex-grow: 0 !important;\n  margin-left: 10px;\n  padding: 10px;\n  width: 25px;\n  height: 25px;\n  margin-top: -2px;\n}", ""]);
// Exports
module.exports = exports;
