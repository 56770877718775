<template>
  <v-col md="12">
    <div class="upsell-group-edit-card">
      <v-card class="elevation-1">
        <v-card-title class="pb-0 mb-1"></v-card-title>
        <v-card-text class="pa-0">
          <v-list-item class="tile">
            <v-list-item-action></v-list-item-action>
            <v-row class="mb-0" >
              <v-col md="3">
                <v-list-item-title>Item Name</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title>Item ID</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title>Cost</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title></v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list v-if="localItems.length" ref="sortableList" hide-actions class="el2 pt-0 pb-0">
            <v-list-item v-for="item in localItems" :key="item.id" class="tile">
              <v-list-item-action>
                <div class="icon icon--handle"></div>
              </v-list-item-action>
              <v-row class="mb-0" >
                <v-col md="3">
                  <v-list-item-title>{{ item.inventoryItemName }}</v-list-item-title>
                </v-col>
                <v-col md="3">
                  <v-list-item-title>{{ item.InventoryItemID }}</v-list-item-title>
                </v-col>
                <v-col md="3">
                  <v-list-item-title>{{ item.costDisplay }}</v-list-item-title>
                </v-col>
                <v-col md="3">
                  <v-list-item-title>
                    <div @click="deleteItem(item)" class="delete-logo"/>
                  </v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-card-text v-else>No items assigned.</v-card-text>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-row class="action-row" @click.stop="onAdd">
            <div class="upsell-logo"/>
            <div class="action-row__title">Add Items</div>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    <EditingModal v-if="upsellSelectionVisible" class="modal">
      <UpsellSelectionCard
        :title="selectionCardTitle"
        :preSelectedItems="modalItems"
        @close="closeModal"
        @save="handleNewSelections"
      />
    </EditingModal>
  </v-col>
</template>

<script>
import Sortable from "sortablejs";
import UpsellSelectionCard from "components/upsell/upsell_selection_card.vue";
import EditingModal from "components/editing_modal.vue";
import clone from "clone";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            localItems: this.group.items,
            modalItems: clone(this.group.items),
            upsellSelectionVisible: false
        };
    },
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    components: {
        UpsellSelectionCard,
        EditingModal
    },
    computed: {
        selectionCardTitle() {
            return `Select items for the group`
        },
        ...mapGetters(["productsMap"])
    },
    methods: {
        dragReorder({ oldIndex, newIndex }) {
            const movedItem = this.localItems.splice(oldIndex, 1)[0];
            this.localItems.splice(newIndex, 0, movedItem);
            this.emitItemsChange();
        },
        onAdd() {
            this.modalItems = clone(this.localItems);
            this.upsellSelectionVisible = true;
            this.emitItemsChange();
        },
        deleteItem(item) {
            let index = this.localItems.findIndex(el => el.id == item.id);
            this.localItems.splice(index, 1);
            this.emitItemsChange();
        },
        closeModal() {
            this.upsellSelectionVisible = false;
        },
        handleNewSelections(selectedItems) {
            this.upsellSelectionVisible = false;
            this.localItems = selectedItems
                .filter(el => !el.group)
                .map(el => {
                    return {
                        InventoryItemID: el.id,
                        Order: el.order == undefined ? -1 : el.order,
                        Position: this.upsellSelectionPosition,
                        ...this.productsMap[`${el.id}`]
                    };
                });
            this.emitItemsChange()
        },
        emitItemsChange() {
            this.$emit("onChange", this.localItems);
        }
    },
    mounted() {
        if (this.$refs.sortableList === undefined) return;
        new Sortable(this.$refs.sortableList.$el, {
            handle: ".icon--handle",
            onStart: () => {},
            onEnd: this.dragReorder
        });
    },
    watch: {
        group(newValue, oldValue) {
            if (newValue.items == undefined) return;
            this.localItems = newValue.items;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~scss/variables";
@import "~css/vuebar.css";

.action-row {
  padding: 15px;
  cursor: pointer;
  margin-top: 0 !important;
  margin-left: -8px !important;
  margin-right: -8px !important;
  &__title {
    margin-left: 10px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
}
.upsell-group-edit-card {
  .tile {
    border-bottom: 1px solid #e4e4e4;
  }

  .header {
    .v-list__tile {
      color: purple;
    }
  }
}
.v-card {
  &__title {
    color: #585858 !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  &__text {
    color: #585858 !important;
    font-size: 14px;
    overflow: hidden;
  }
}

.v-list {
  margin-right: 5px;

  &__tile {
    border-bottom: 1px solid #e4e4e4;

    &__title {
      color: #585858 !important;
      font-size: 14px;
    }

    &__action {
      min-width: 35px;
      margin-left: 5px;

      .icon {
        cursor: grab;
      }
    }
  }
}

.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");
  margin-left: 5px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}

.delete-logo {
  @include background-image("", "delete.svg", "../../img/");
  background-size: 25px;
  cursor: pointer;
  flex-grow: 0 !important;
  margin-left: 10px;
  padding: 10px;
  width: 25px;
  height: 25px;
  margin-top: -2px;
}
</style>
